
// export const environment = {
//     production: true,
//     apiBaseUrl: "https://portalapi.credsure.redianglobal.com",
//     ImageBaseUrl: "https://adminapi.credsure.redianglobal.com",
//     frontEndBaseUrl: "https://portalapi.credsure.redianglobal.com",
//     frontEndUrl: "https://portalapi.credsure.redianglobal.com",
//     Currency: 'KES',
//     enc_key: '1234567890123456',
//     VehicleDropDownStartYear: 1971,
//     googleAutoCompleteApi: 'AIzaSyDtpifMidVYOOPShcG1eEWOIlQ3YVAhN6Q',
//     mapAPIKey: 'AIzaSyDtpifMidVYOOPShcG1eEWOIlQ3YVAhN6Q',
//   };


export const environment = {
  production: true,
  apiBaseUrl: "https://portalapi.redinsure.redianglobal.com",
  ImageBaseUrl: "https://adminapi.redinsure.redianglobal.com",
  frontEndBaseUrl: "https://portalapi.redinsure.redianglobal.com",
  frontEndUrl: "https://portalapi.redinsure.redianglobal.com",
  Currency: 'KES',
  enc_key: '1234567890123456',
  VehicleDropDownStartYear: 1971,
  googleAutoCompleteApi: 'AIzaSyDtpifMidVYOOPShcG1eEWOIlQ3YVAhN6Q',
  mapAPIKey: 'AIzaSyDtpifMidVYOOPShcG1eEWOIlQ3YVAhN6Q',
};



