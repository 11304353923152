import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
environment
@Injectable({
  providedIn: 'root'
})
export class PolicyService {
  private apiBaseUrls: string = environment.apiBaseUrl;
  private token: any;
  private header: any;
  private header_with_token: any;
  constructor(private http: HttpClient) {
    this.header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
    };
    // this.header_with_token = {
    //   headers: new HttpHeaders()
    //     .set('Content-Type', `application/json`)
    //     .set('Authorization', `${'Bearer: ' + this.token}`)
    // };
  }
  getUserPolicy(FormData) {
    let Url = `${this.apiBaseUrls}/policyManagement/list`;
    // console.log(Url, FormData);
    this.setHeaderwithToken();
    return this.http.post(`${Url}`,FormData,this.header_with_token);
  }

  getPolicyData(policyId) {
    let Url = `${this.apiBaseUrls}/policyManagement/show/${policyId}`;
    this.setHeaderwithToken();
    return this.http.get(`${Url}`, this.header_with_token);
  }

  getRelations(){
    let url = `${this.apiBaseUrls}/kin/getRelationship`;
    // console.log(url);
    this.setHeaderwithToken();
    return this.http.get(`${url}`, this.header_with_token);
  }

  setHeaderwithToken(){
    this.token = localStorage.getItem('token');
    this.header_with_token = {
      headers: new HttpHeaders()
        // .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    };
  }

  savePolicyPaymentInfo(formData){
    let url = `${this.apiBaseUrls}/policyManagement/payment_details`;
    this.setHeaderwithToken();
    return this.http.post(`${url}`,formData, this.header_with_token);
  }

// getpolicy_pdf(FormData, params) {
//   let Url = `${this.apiBaseUrls}/policyManagement/generate_policy/${params}`;
//   this.setHeaderwithToken();

//   return this.http.post(Url, FormData, {
//     headers: this.header_with_token,
//     responseType: 'blob'  // Expecting a Blob in response
//   }).pipe(
//     tap((response) => {
//       // Log the response to check if it's a valid Blob (PDF)
//       console.log('Response:', response);
//       const contentType = response.type;
//       console.log('Content-Type:', contentType);
//     })
//   );
// }

getpolicy_pdf(FormData: any, params: string) {
  const Url = `${this.apiBaseUrls}/policyManagement/generate_policy/${params}`;
  this.setHeaderwithToken();
 
  return this.http.post(Url, FormData, {
    ...this.header_with_token,
    responseType: 'blob' as 'json', // Handle binary data (PDF)
  });
}


  claim_policy(FormData) {
    let Url = `${this.apiBaseUrls}/policyClaim/createClaim`;
    this.setHeaderwithToken();
    return this.http.post(`${Url}`,FormData,this.header_with_token);
  }  

}

